
    import {Component, Model, Prop, PropSync, Vue} from "vue-property-decorator";
    import {SelectItem, selectItemsToValues} from "@/domain";

    @Component
    export default class FilterSelect extends Vue {

        @Prop()
        readonly label!: string;

        @Prop()
        readonly maxSelection!: number

        @Prop({ type: Array, default: () => [] })
        readonly items!: SelectItem[]

        @PropSync('selected')
        selectedModel!: any[];
    }
