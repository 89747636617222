export enum MessageDataRange {
    Last24Hours = "LAST_24_HOURS",
    Last3Days = "LAST_3_DAYS",
    LastWeek = "LAST_WEEK",
    Last3Weeks = "LAST_3_WEEKS",
    Last6Weeks = "LAST_6_WEEKS"
}

export interface Message {
    id: number;
    date: Date;
    chargePointName: string;
    centralSystemName: string;
    messageVersion: string;
    messageAction: string;
    messageType: string;
    headers: string;
    payload: string;
    chargePointMessage: string;
}

export interface StatisticMessage {
    date: Date;
    messageType: string;
    count: number;
}
