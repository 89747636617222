
    import {Component, Vue} from "vue-property-decorator";
    import {MessageDataRange} from "@/domain";

    @Component
    export default class MessagesRangeFilter extends Vue {
        readonly availableDateRanges = [{
            text: "Last 24 Hours",
            value: MessageDataRange.Last24Hours
        }, {
            text: "Last 3 Days",
            value: MessageDataRange.Last3Days
        }, {
            text: "Last Week",
            value: MessageDataRange.LastWeek
        }, {
            text: "Last 3 Weeks",
            value: MessageDataRange.Last3Weeks
        }, {
            text: "Last 6 Weeks",
            value: MessageDataRange.Last6Weeks
        }];

        get dateRange(): MessageDataRange {
            return this.$store.state.messages.dateRange || MessageDataRange.Last24Hours;
        }

        set dateRange(val: MessageDataRange) {
            this.$store.commit('messages/SET_FILTER', {
                name: 'range',
                value: val
            });
            this.$emit('change', val);
        }
    }
