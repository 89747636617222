
    import {Component, Vue} from "vue-property-decorator";
    import {DataTableHeader} from "vuetify";
    import {namespace} from "vuex-class";
    import moment from "moment";

    import {Message} from "@/domain";
    import MessagesFilterPopout from "@/components/MessagesFilterPopout.vue";
    import {Pageable} from "@/domain/api";
    import MessagesRangeFilter from "@/components/MessagesRangeFilter.vue";

    const messagesModule = namespace('messages');

    @Component({
        components: {MessagesRangeFilter, MessagesFilterPopout},
        filters: {
            date(value: string) {
                return moment(value).format("YYYY-MM-DD hh:mm:ss")
            }
        },
        data: () => ({
            footerProps: {
                itemsPerPageOptions: [10,20,50,250]
            }
        }),
    })
    export default class CrudDataTable extends Vue {

        @messagesModule.State
        readonly loading!: boolean;

        @messagesModule.State
        readonly items!: Message[];

        @messagesModule.State
        readonly totalItems!: number;

        @messagesModule.Getter('dataTableOptions')
        readonly options!: any;

        headers: DataTableHeader[] = [
            { text: 'ID', value: 'id' },
            { text: 'Date', value: 'date' },
            { text: 'Type', value: 'messageType' },
            { text: 'Version', value: 'messageVersion' },
            { text: 'Action', value: 'messageAction' },
            { text: 'Charge Point', value: 'chargePointName' },
            { text: 'Central System', value: 'centralSystemName' }
        ];

        onRefresh() {
            this.$store.dispatch("messages/fetchPage");
        }

        onClickMessage(item: Message) {
            this.$router.push({
                name: 'messages-dialog-info',
                params: {
                    id: item.id.toString()
                }
            });
        }

        optionsUpdated(options: any) {
            this.$store.dispatch("messages/setOptions", options);
            this.onRefresh()
        }
    }
