
    import {Component, Vue} from "vue-property-decorator";
    import * as messageFilterConfig from "@/assets/message-filters.json";
    import {namespace} from "vuex-class";
    import {Filter} from "@/domain/api";
    import FilterSelect from "@/components/FilterSelect.vue";
    import {CentralSystem, ChargePoint} from "@/domain";

    const messagesModule = namespace('messages');

    @Component({
        components: {FilterSelect}
    })
    export default class MessagesFilterPopout extends Vue {

        @messagesModule.State
        private readonly filters!: { [name: string]: any };

        @messagesModule.Mutation('SET_FILTER')
        private readonly setFilter!: (filter: Filter) => void;

        @messagesModule.Mutation('CLEAR_FILTERS')
        private readonly clearFilters!: () => void;

        readonly availableTypes = messageFilterConfig.types;
        readonly availableVersions = messageFilterConfig.versions;
        readonly availableActions = messageFilterConfig.actions;

        get totalFilters() {
            return [this.selectedTypes, this.selectedVersions, this.selectedActions, this.selectedChargePoints, this.selectedCentralSystems]
                .reduce((sum: number, array: any[]) => sum + array.length, 0)
        }

        get canClear() {
            return this.totalFilters > 0;
        }

        get availableChargePoints() {
            return (this.$store.state.chargePoints.items as ChargePoint[]).map(cp => cp.name);
        }

        get availableCentralSystems() {
            return (this.$store.state.centralSystems.items as CentralSystem[]).map(cp => cp.name);
        }

        get selectedTypes(): string[] {
            return this.filters.types || [];
        }

        set selectedTypes(val: string[]) {
            this.setFilter({
                name: 'types',
                value: val
            });
        }

        get selectedVersions(): string[] {
            return this.filters.versions || [];
        }

        set selectedVersions(val: string[]) {
            this.setFilter({
                name: 'versions',
                value: val
            });
        }

        get selectedActions(): string[] {
            return this.filters.actions || [];
        }

        set selectedActions(val: string[]) {
            this.setFilter({
                name: 'actions',
                value: val
            });
        }

        get selectedChargePoints(): string[] {
            return this.filters.chargePoints || [];
        }

        set selectedChargePoints(val: string[]) {
            this.setFilter({
                name: 'chargePoints',
                value: val
            });
        }

        get selectedCentralSystems(): string[] {
            return this.filters.centralSystems || [];
        }

        set selectedCentralSystems(val: string[]) {
            this.setFilter({
                name: 'centralSystems',
                value: val
            });
        }

        async mounted() {
            await this.$store.dispatch("chargePoints/fetchAll");
            await this.$store.dispatch("centralSystems/fetchAll");
        }
    }
