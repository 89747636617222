import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v("Messages")]),_c(VSpacer),_c('messages-range-filter',{staticClass:"mr-2",on:{"change":_vm.onRefresh}}),_c(VBtn,{staticClass:"mr-2",attrs:{"fab":"","depressed":"","small":"","loading":_vm.loading},on:{"click":_vm.onRefresh}},[_c(VIcon,[_vm._v("mdi-refresh")])],1),_c('messages-filter-popout',{on:{"apply":_vm.onRefresh}})],1),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalItems,"footer-props":_vm.footerProps,"options":_vm.options},on:{"click:row":_vm.onClickMessage,"update:options":_vm.optionsUpdated},scopedSlots:_vm._u([{key:"item.date",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("date")(item.date))+" ")]}}])})],1),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }