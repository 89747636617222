import {LinkedCentralSystem} from "@/domain/centralsystem";

export interface ChargePoint {
    id: number;
    name: string;
    type: string;
    ocppVersion: string;
    uri: string | null;
    useManualURI: boolean;
    useRequestHeader: boolean;
    omitSoapHeader: boolean;
    basicAuthStatus: BasicAuthStatus;
    basicAuthPassword?: string | null;
    basicAuthDeviationId?: number | null;
    networkProfileId?: number;
    // Purely for display purposes
    networkProfileName?: string;
}

export interface ChargePointWithLinked extends ChargePoint {
    linkedCentralSystems: (LinkedCentralSystem & { centralSystem: number })[];
}

export enum BasicAuthStatus {
    NOT_REQUIRED = "NOT_REQUIRED",
    REQUIRED = "REQUIRED",
    CONFIGURE_NEXT_CONNECT = "CONFIGURE_NEXT_CONNECT",
    ENABLE_NEXT_CONNECT = "ENABLE_NEXT_CONNECT",
}
